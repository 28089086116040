import { getClientConfig } from './config';
import { appendPathname } from './utils/url';

export function getWeblicantJobUrl(jobId: number) {
    const { weblicantUrl: weblicantBaseUrl } = getClientConfig();

    return appendPathname(weblicantBaseUrl, `/jobs/${jobId}/`);
}

export type GetWeblicantJobPreviewUrlOptions = {
    loginCode?: string;
};

export function getWeblicantJobPreviewUrl(jobId: number, { loginCode }: GetWeblicantJobPreviewUrlOptions) {
    const { weblicantUrl: weblicantBaseUrl } = getClientConfig();

    const url = new URL(appendPathname(weblicantBaseUrl, `/preview/jobs/${jobId}/`));

    if (!!loginCode) {
        url.searchParams.set('login-code', loginCode);
    }

    return url.href;
}
