import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_STATUS } from '../../modules/constants';
import { startAdvertiseCheckout } from '../../modules/checkout/actions';
import { getJobByLocalCreateId, getJobCreateErrors, getJobCreateStatus } from '../../modules/entities/selectors';
import { createJob, removeCreateJobLocalId } from '../../modules/jobs/actions';
import { Job } from '../../modules/jobs/types';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';
import { waitForResolve } from '../../utils/redux/utils';
import JobFormPage, { JobFormPageJobPartial, JobFormSubmissionError } from '../JobFormPage';
import { ApplicationState } from '../../store/types';
import {
    getCompanyProfileSettingsRoute,
    GetCompanyProfileSettingsRouteOptions,
    getJobEditRoute,
    getJobsRoute
} from '../../routes';
import { createDraftJob, isActive, isDraft } from '../../modules/jobs/utils';
import {
    JobFormPageSubmissionType,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE,
    JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP
} from '../JobFormPage/JobFormPage.constants';
import { getLoginCode } from '../../modules/authentication/api';
import { getWeblicantJobPreviewUrl } from '../../externalUrls';

export type JobCreateFormPageContainerProps = {};

function JobCreateFormPageContainer({}: JobCreateFormPageContainerProps) {
    const history = useHistory();

    const store = useStore();
    const wait = React.useMemo(() => {
        return waitForResolve(store);
    }, [store]);

    const recruiter = useSelector(getRecruiter) as Recruiter;
    const dispatch = useDispatch();

    const job = React.useMemo(() => {
        return createDraftJob(recruiter);
    }, []);

    async function saveWithCreate(jobData): Promise<Job> {
        const localCreateId = uuidv4();

        const mapStateToState = (state: ApplicationState) => {
            return {
                jobCreateStatus: getJobCreateStatus(state, localCreateId),
                jobCreateErrors: getJobCreateErrors(state, localCreateId),
                job: getJobByLocalCreateId(state, localCreateId)
            };
        };

        const connector = wait(mapStateToState);

        const onInit = function onInit() {};
        const onChange = function onChange(this: any, prevState, nextState) {
            if (prevState.jobCreateStatus === CREATE_STATUS.CREATING) {
                if (nextState.jobCreateStatus === CREATE_STATUS.CREATED) {
                    this.resolve(nextState.job);
                } else if (nextState.jobCreateStatus === CREATE_STATUS.FAILED) {
                    const error = new JobFormSubmissionError(nextState.jobCreateErrors);
                    this.reject(error);
                }
            }
        };

        const connectPromise: Promise<Job> = connector({ onInit, onChange });

        dispatch(createJob(jobData, localCreateId));

        const job = await connectPromise;

        dispatch(removeCreateJobLocalId(localCreateId));

        return job;
    }

    const lastCreatedJobIdRef = React.useRef<number | null>(null);

    const handleSubmit = async (
        jobPartial: JobFormPageJobPartial,
        submissionType: JobFormPageSubmissionType | null
    ): Promise<void> => {
        const updatedJob: Job = await saveWithCreate(jobPartial);
        lastCreatedJobIdRef.current = updatedJob.id;

        if (submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE) {
            history.push(getJobsRoute());
        } else if (submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP) {
            history.replace(getJobEditRoute(updatedJob.id));

            if (isDraft(updatedJob)) {
                const { data: loginCodeResponseData } = await getLoginCode(true);

                window.open(
                    getWeblicantJobPreviewUrl(updatedJob.id, {
                        loginCode: loginCodeResponseData.login_code
                    }),
                    `preview-${updatedJob.id}`
                );
            } else if (isActive(updatedJob)) {
                window.open(updatedJob.static_url);
            }
        } else if (submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT) {
            history.replace(getJobEditRoute(updatedJob.id));
            dispatch(startAdvertiseCheckout(updatedJob));
        }
    };

    const handleCancel = () => {
        history.push(getJobsRoute());
    };

    const handleOpenSettings = () => {
        const options: GetCompanyProfileSettingsRouteOptions = {};

        if (typeof lastCreatedJobIdRef.current === 'number') {
            options.backToJob = lastCreatedJobIdRef.current;
        }

        history.push(getCompanyProfileSettingsRoute(options));
    };

    return (
        <JobFormPage
            recruiter={recruiter}
            job={job}
            isParsed={false}
            isUpgraded={false}
            initialValidation={false}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onOpenSettings={handleOpenSettings}
        />
    );
}

export default JobCreateFormPageContainer;
