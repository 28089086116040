import { Button } from '@truffls/design-system-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getWeblicantJobPreviewUrl } from '../../externalUrls';
import { getLoginCode } from '../../modules/authentication/api';
import { Job } from '../../modules/jobs/types';
import { isDraft, isActive } from '../../modules/jobs/utils';

export type JobActionViewInAppProps = {
    job: Job;
};

function JobActionViewInApp({ job }: JobActionViewInAppProps) {
    if (!isDraft(job) && !isActive(job)) {
        return null;
    }

    const handleClickPreview = async () => {
        const { data } = await getLoginCode(true);

        window.open(
            getWeblicantJobPreviewUrl(job.id, {
                loginCode: data.login_code
            }),
            `preview-${job.id}`
        );
    };

    if (isActive(job)) {
        return (
            <Button component="a" href={job.static_url} typeStyle="outlined" variationStyle="normal">
                <FormattedMessage id="JOB_ACTION_VIEW_IN_APP.LABEL" />
            </Button>
        );
    }

    return (
        <Button type="button" onClick={handleClickPreview} typeStyle="outlined" variationStyle="normal">
            <FormattedMessage id="JOB_ACTION_VIEW_IN_APP.PREVIEW_LABEL" />
        </Button>
    );
}

export default JobActionViewInApp;
